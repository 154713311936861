import _ from "lodash";

export default {
  data: () => ({
    windowHeight: document.documentElement.clientHeight,
    currentPage: 1,
    loadMoreDisabled: false,
    isLoadingItems: false,
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize(e) {
      this.windowHeight = e.target.innerHeight;
    },
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
  },
  computed: {
    perPage() {
      let count = 1;
      if(Math.ceil((this.windowHeight - this.offsetBottom) / 35) > 0)
      {
        count = Math.ceil((this.windowHeight - this.offsetBottom) / 35)
      }
      return count;
    },
    getTableHeight() {
      return this.windowHeight - (this.offsetBottom - 90) + "px";
    }
  },
};
