<template>
  <div class="mb-2">
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        absolute
        top
    ></v-progress-linear>
    <v-card outlined color="transparent">
      <v-card-text class="pa-0">
        <v-card>
          <v-card-title>
            <v-row no-gutters justify="space-between" align="center" class="mx-4">
              <v-col cols="3">
                {{ $t("form.streams") }}
              </v-col>
              <v-col cols="auto">
                <v-btn @click="createEditStream()">
                  {{$t('home.create_stream')}}
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="streamsHeaders"
              :items="streamsList"
              :items-per-page="streamsList.length"
              class="mt-4 my-table"
              dense
              outlined
              hide-default-footer
              :height="getTableHeight"
            >
              <template v-slot:item.btn="{ item }">
                  <v-btn
                      class="btn-edit sm mr-3"
                      color="primary"
                      dense
                      small
                      @click="createEditStream(item)"
                  >
                    <v-icon dense>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
              </template>
              <template v-slot:item.price="{ item }">
                {{ item?.prices[0]?.price ? item?.prices[0]?.price : '0.00' }}
              </template>
              <template v-slot:item.discount="{ item }">
                {{ item.discount }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon @click="showDeletedStreamDialog(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.url="{ item }">
                <v-btn
                  v-if="item.landings.length"
                  target="_blank"
                  text
                  class="pa-0"
                  style="text-decoration: underline; color: inherit;"
                  @click="copyToClipboard(`${apiUrl}/stream?uuid=${item.id}`)"
                >
                {{ $t("streams.copy") }}
                </v-btn>
                <v-menu v-else v-model="menu[item.id]" offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="orange" icon small v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi-alert</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    {{ $t("form.no_landings") }}
                  </v-card-text>
                </v-card>
              </v-menu>
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <td :colspan="12">
                    <v-progress-linear
                        v-if="isLoadingItems"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    <div
                        v-observer="{ nextPage: nextPage }"
                        class="observer"
                        v-if="!loadMoreDisabled && !isLoadingItems"
                    ></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <add-edit-stream-dialog v-if="streamDialog" :selectedStream="selectedItem" :visible="streamDialog" @streamModified="streamModified" @createStream="createStream" @close="streamDialog = false" :type="streamDialogType"/>
    <deleteConfirmDialog
        :visible="showDeleteDialog"
        @close="showDeleteDialog = false"
        @delete="deleteStream"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import streamsService from "@/services/request/streams/streamsService";
import dataTableMixin from "@/mixins/dataTableMixin";
import AddEditStreamDialog from "@/components/streams/addEditStreamDialog.vue";
import loader from "@/mixins/loader";

export default {
  components: {AddEditStreamDialog, DeleteConfirmDialog },
  mixins: [notifications, dataTableMixin, loader],
  data: () => ({
    loading: false,
    geo: {
      name: '',
      country_code: '',
    },
    category: {
      name: '',
      icon: 'mdi-folder'
    },
    geoList: [],
    categoryList: [],
    streamsList: [],
    showDeleteDialog: false,
    item: {},
    offsetBottom: 265,
    menu: {},
    selectedItem: {},
    streamDialog: false,
    streamDialogType: 'create'
  }),
  computed: {
    ...mapGetters(["currentLanguage"]),
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    streamsHeaders() {
      return [
        { text: this.$t('profile.editing'), align: "center", sortable: false, value: "btn"},
        { text: this.$t('settings.name'), value: 'name' },
        { text: this.$t('settings.price'), value: 'price' },
        { text: this.$t('settings.discount'), value: 'discount' },
        { text: 'Url', value: 'url', sortable: false },
        { text: this.$t('form.deleteImgDialog.delete_dialog_title'), value: 'action', sortable: false  },
      ];
    }
  },
  methods: {
    createEditStream(item) {
      if(item) {
        this.selectedItem = item;
        this.streamDialogType = 'edit'
        this.streamDialog = true;
      } else {
        this.selectedItem = {};
        this.streamDialogType = 'createStream'
        this.streamDialog = true;
      }
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    async nextPage() {
      this.currentPage += 1;
      this.isLoadingItems = true;
      await this.fetchStreams(this.currentPage);
      this.isLoadingItems = false;
    },
    async fetchStreams(page) {
      try {
        this.isLoadingItems = true;
        const params = {};
        params.page = page;
        params.per_page = this.perPage;
        const response = await streamsService.getStreams(params);
        if (response && response.length > 0) {
          if (page > 1) {
            this.streamsList = [...this.streamsList, ...response];
          } else {
            this.streamsList = response;
          }
        }
        this.loadMoreDisabled = response.length < this.perPage;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoadingItems = false;
      }
    },
    async deleteStream() {
      try {
        await streamsService.deleteStream(this.item.id);
        this.setSuccessNotification(this.$t("form.deleteSuccess"));
        this.fetchStreams();
        this.item = {};
      } catch (e) {
        console.log(e);
      }
    },
    showDeletedStreamDialog(item) {
      this.resetItem();
      this.item = { name: item.name, id: item.id };
      this.showDeleteDialog = true;
    },
    resetItem() {
      this.item = {};
    },
    createStream(newStream) {
        this.streamsList?.unshift(newStream);
    },
    streamModified(modifiedStream) {
      const index = this.streamsList?.findIndex((item) => item.id === modifiedStream.id);
      if (index !== -1) {
        this.$set(this.streamsList, index, modifiedStream);
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchStreams(1);
    this.setLoading(false);
  }
};
</script>

<style scoped>
.custom-v-row {
  margin-bottom: 16px;
}
</style>
