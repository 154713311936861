<template>
  <StreamsComponent />
</template>


<script>

import StreamsComponent from "@/components/streams/streamsComponent.vue";

export default {
  name: "StreamsView",
  components: {
    StreamsComponent,
  },
};
</script>